import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import queryString from "query-string"
import moment from "moment"
import theme from "../../../theme"
import DatePicker from "./DatePicker"
import HotelFilters from "./hotelFilters"
import { Button } from "@material-ui/core"
import Search from "@material-ui/icons/Search"
import Autocomplete from "./locationAutocomplete"

const passengerDetails = {
  passengerClass: "Economy",
  adults: 2,
  children: 0,
  childrenAge: [],
  infants: 0,
  oneWay: false,
  rooms: 1,
}

const buildKids = (kids, ages) => {
  let ageItems = ages ? [] : null
  for (let i = 0; i < kids; i++) {
    const age = ages[i].age || 5
    ageItems.push(age)
  }
  return ageItems.join(",")
}

export default function Hotels({ t }) {
  const [hotelDetails, setHotelDetails] = useState({
    city: "",
    adults: 1,
    children: 0,
    childrenAges: [],
    rooms: 1,
    startDate: moment().add(7, "days"),
    endDate: moment().add(8, "days"),
  })

  const [destination, setDestination] = useState(null)
  const [destinations, setDestinations] = useState([])
  const [formErrors, setFormErrors] = useState({
    destination: false,
    startDate: false,
    endDate: false,
  })

  const [inputValue, setInputValue] = useState("")

  // Validates departure and arrival destination fields
  async function validateFields(type) {
    const re = /\((.*)\)/i

    const { city, startDate, endDate } = hotelDetails
    const errors = {
      startDate: startDate === null,
    }

    switch (type) {
      case "city":
        errors.city = city === ""
        break
      case "submit":
        errors.city = city === ""
        errors.endDate = !endDate
        break
      default:
        console.warn("Can not evaluate property of type.")
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return errors
  }

  const onDateChange = (startDate, endDate) => {
    setHotelDetails({
      ...hotelDetails,
      startDate,
      endDate,
    })

    setFormErrors({
      ...formErrors,
      startDate: false,
      endDate: false,
    })
  }

  const searchHotel = (e) => {
    e.preventDefault()

    let redirect = ""
    const re = /\((.*)\)/i

    // Check for any errors before submitting
    return validateFields("submit").then((res) => {
      // Checks if the errors in state contains any truthy values
      let errors = Object.keys(res).some((k) => res[k])
      if (!errors) {
        let query = {
          check_in: hotelDetails.startDate.format("YYYY-MM-DD"),
          check_out: hotelDetails.endDate.format("YYYY-MM-DD"),
          r1: paxes,
        }

        let paxes = hotelDetails.adults_0
        if (hotelDetails.childrenAge_0.length > 0) {
          paxes += ","
          paxes += hotelDetails.childrenAge_0.map((kid) => kid.age).join(",")
        } else if (hotelDetails.ages_0) {
          paxes += ","
          paxes += buildKids(hotelDetails.children_0, hotelDetails.ages_0)
        }

        ;[...Array(hotelDetails.rooms)].map((n, i) => {
          let paxes = hotelDetails[`adults_${i}`]
          if (hotelDetails[`childrenAge_${i}`].length > 0) {
            paxes += ","
            paxes += hotelDetails[`childrenAge_${i}`]
              .map((kid) => kid.age)
              .join(",")
          }

          query = {
            ...query,
            [`r${i + 1}`]: paxes,
          }
        })

        if (destination && destination.types.includes("country")) {
          redirect = `${process.env.TRAVALA_URL}/hotels/${destination.slug}`
        } else if (destination && destination.code) {
          const search = queryString.stringify(query)

          redirect = `${process.env.TRAVALA_URL}/hotel/${destination.slug}?${search}`
        } else {
          const searchBase = queryString.stringify(
            {
              ...query,
              location: destination.id,
              place_types: destination.types,
            },
            { arrayFormat: "comma" }
          )

          redirect = `${process.env.TRAVALA_URL}/search?${searchBase}`
        }
      } else {
        return
      }

      window.open(redirect, "_blank")
      // Navigate to the search
      // window.location.href = redirect
    })
  }

  const onFilterChange = (inputs) => {
    const { adults, children, childrenAge, rooms } = inputs

    setHotelDetails({
      ...hotelDetails,
      ...inputs,
      adults,
      children,
      childrenAges: childrenAge,
      rooms,
    })
  }

  // Runs when x in field is clicked
  const clearField = (field) => {
    setHotelDetails({
      ...hotelDetails,
      city: "",
    })

    setFormErrors({
      ...formErrors,
      [field]: false,
    })
    setInputValue("")
  }

  const setLocation = (location, name) => {
    setHotelDetails({
      ...hotelDetails,
      [name]: location,
    })
  }

  // Runs whenever user enters or changes any inputs
  const onInputChange = (name, text, location) => {
    setInputValue(text)

    let value = ""
    if (location) {
      if (location.id) {
        value = location.id
      } else if (location.code) {
        value = location.code
      }
    }

    // Check if they have entered or chosen a valid value
    if (location) {
      setFormErrors({
        ...formErrors,
        city: false,
      })
    }
    setLocation(value, "city")
    setDestination(location)
  }

  return (
    <FormArea>
      <form autoComplete="off" onSubmit={searchHotel}>
        <input style={{ display: "none" }} type="text" name="fakeusername" />
        <input
          style={{ display: "none" }}
          type="password"
          name="fakepassword"
        />
        <div className="top-row">
          <Autocomplete
            onValueChange={onInputChange}
            isHotel={true}
            name="destination"
            validateFields={validateFields}
            value={inputValue}
            validation={formErrors.city}
            t={t}
          />
        </div>
        <div className="options">
          <DatePicker
            isHotel={true}
            onDateChange={onDateChange}
            startDate={hotelDetails.startDate}
            endDate={hotelDetails.endDate}
            formErrors={formErrors}
            t={t}
          />
          <HotelFilters
            initialValues={passengerDetails}
            onChange={onFilterChange}
            t={t}
          />
          <div className="search-button-container">
            <Button type="submit" variant="contained" className="search-button">
              <Search />
              {t("Search Hotels")}
            </Button>
          </div>
        </div>
      </form>
    </FormArea>
  )
}

const FormArea = styled.div`
  color: #333;

  form {
    display: flex;
    flex-direction: row;
  }

  * {
    font-family: ProximaNova;
  }

  .active {
    border-bottom: 1px solid #333;
  }

  .trip-type {
    margin-bottom: 20px;

    span {
      margin-right: 68px;
    }
  }

  .top-row {
    display: flex;
    align-items: center;
  }

  .switch {
    color: #969696;
    margin: 0 15px;

    &:hover {
      color: ${theme.orange};
      cursor: pointer;
    }
  }

  .options {
    display: flex;
    justify-content: space-between;

    .MuiInputBase-input {
      font-size: 16px;
      /* font-weight: 600; */
      color: "#58595b";
    }

    .search-button {
      width: 216px;
      height: 48px;
      border-radius: 8px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background-color: #f66415;
      margin-left: 16px;
      .MuiButton-label {
        color: white;
        font-size: 20px;
        font-family: ProximaNova;
        text-transform: capitalize;

        svg {
          font-size: 29px;
          margin-right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 1275px) {
    form {
      flex-direction: column;
    }
    .top-row {
      margin-bottom: 20px;
      > div {
        width: 100%;
      }
    }
    .search-button-container {
      flex-grow: 1;
      .search-button {
        float: right;
      }
    }
  }

  @media only screen and (max-width: 865px) {
    .options {
      width: 100%;
      flex-direction: column;

      .search-button-container {
        margin-top: 30px;
        width: 100%;
        .search-button {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`
