import React, { useState } from "react"
import styled from "styled-components"
import { FaRegBuilding, FaPlane } from "react-icons/fa"
import Flights from "./flights"
import Hotels from "./hotels"

export default function SearchBox({ changeType, t }) {
  const [tripType, setTripType] = useState("flights")

  const setType = (type) => {
    setTripType(type)
    changeType(type)
  }
  return (
    <SearchGroup hotel={tripType === "hotels"}>
      <div className="container">
        <div className="buttons">
          <SearchTypeButton
            type="flight"
            active={tripType === "flights"}
            onClick={() => setType("flights")}
          >
            <FaPlane
              color="#f66415"
              size="17px"
              className="trip-icon"
              style={{ transform: "rotate(310deg)" }}
            />
            <span>{t("Flights")}</span>
          </SearchTypeButton>
          <SearchTypeButton
            type="hotel"
            active={tripType === "hotels"}
            onClick={() => setType("hotels")}
          >
            <FaRegBuilding color="#f66415" size="17px" className="trip-icon" />
            <span>{t("Hotels")}</span>
          </SearchTypeButton>
        </div>
        <div
          className={
            tripType === "hotels" ? "search-area hotel" : "search-area"
          }
        >
          {tripType === "flights" ? <Flights t={t} /> : <Hotels t={t} />}
        </div>
      </div>
    </SearchGroup>
  )
}

const SearchGroup = styled.div`
  margin: 90px auto 0 auto;

  .container {
    width: 1200px;
  }

  .buttons {
    display: flex;
    width: 300px;
    background: white;
    border-radius: 15px 15px 0 0;
    position: relative;
    padding-bottom: 15px;
  }

  .search-area {
    background: #fff;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    padding: 30px 25px;
    position: relative;
    top: -20px;
    z-index: 1;

    &.hotel {
      padding-top: 50px;
      z-index: 2;
    }
  }

  @media only screen and (max-width: 1275px) {
    .container {
      width: 850px;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 865px) {
    margin: 60px 10% 0 10%;
    .container,
    .search-area {
      width: 100% !important;
    }
    .search-area {
      padding: 30px 20px;
    }
    .date {
      position: relative;
      z-index: 4;
    }
  }
  @media only screen and (max-width: 576px) {
    margin: 0px auto 0 auto;
    .container {
      width: 90% !important;
    }
    .search-area {
      width: 100% !important;
    }
  }
`

const SearchTypeButton = styled.div`
  width: 150px;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  color: #f66415;
  position: relative;
  padding-bottom: 5px;

  span {
    font-size: 22px;
  }

  .trip-icon {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.type === "flight" &&
    `
    border-radius: 15px 0 0 0;
  `}

  ${(props) =>
    props.type === "hotel" &&
    `
    border-radius: 0  15px 0 0;
  `}

  ${(props) =>
    props.active &&
    `
    z-index: 2;
    border-radius: 15px 15px 0 0 ;
    background: #fff;
  `}

  ${(props) =>
    props.active &&
    props.type === "flight" &&
    `
    box-shadow: 6px -6px 8px 0 rgba(0,0,0,0.12);
  `}
  ${(props) =>
    props.active &&
    props.type === "hotel" &&
    `
    box-shadow: -4px -12px 13px 3px rgba(0, 0, 0, 0.12);
  `}
`
