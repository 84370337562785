import React, { useState } from "react"
import { FiPlus, FiMinus } from "react-icons/fi"
import { IconButton } from "@material-ui/core"
import styled, { css } from "styled-components"
import { color, fontSize } from "../../../theme"
import { useTranslation } from "react-i18next"

const CountInput = ({
  label,
  name,
  initialCount,
  min,
  max,
  isHotel,
  onChange,
}) => {
  const [count, setCount] = useState(initialCount)

  const handleChange = (upOrDown) => {
    const newCount = upOrDown === "increment" ? count + 1 : count - 1
    onChange({
      target: {
        name,
        value: newCount,
      },
    })
    setCount(newCount)
  }
  const { t } = useTranslation()

  return (
    <Counter>
      <div className="labels">
        {t(label)} {label === "Adults" && !isHotel && <age>(age: 12+)</age>}{" "}
        {label === "Children" && !isHotel && <age>(age: 2 - 12)</age>}
      </div>
      <Controls>
        <StyledIconButton
          aria-label="Remove passenger"
          disabled={count === min}
          onClick={() => handleChange("decrement")}
          orange="true"
        >
          <FiMinus />
        </StyledIconButton>
        <Count> {count} </Count>
        <StyledIconButton
          aria-label="Add passenger"
          disabled={count === max}
          onClick={() => handleChange("increment")}
          orange="true"
        >
          <FiPlus />
        </StyledIconButton>
      </Controls>
    </Counter>
  )
}

export default CountInput

const Counter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSize.body};
  margin-bottom: 15px;

  age {
    font-weight: 200;
    font-size: ${fontSize.small};
  }
  .labels {
    margin-left: 8px;
  }
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Count = styled.div`
  text-align: center;
  min-width: 20px;
`

const StyledIconButton = styled(IconButton)`
  border: 1px solid ${color.black12} !important;
  border-radius: 50% !important;
  padding: 7px !important;
  margin: 0 5px !important;
  svg {
    font-size: 16px !important;
    color: ${color.tangerine};
  }
  ${(props) =>
    props.disabled &&
    css`
      svg {
        color: ${color.black12};
      }
    `}
`
