import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import Hero from "../components/homepage/hero"
import Rewards from "../components/homepage/rewards"
import Coins from "../components/homepage/coins"
import Destinations from "../components/homepage/destinations"
import Warning from "../components/homepage/warning"
import { useTranslation } from "react-i18next"

export default function NewHome() {
  const { t } = useTranslation()

  return (
    <Home>
      <SEO title="Book Your Flights and Accommodation" />
      <Hero t={t} />
      <div className="row">
        <Warning t={t} />
      </div>
      <div className="row">
        <Rewards t={t} />
      </div>
    </Home>
  )
}

const Section = styled.div`
  height: 1000px;

  @media only screen and (max-width: 1519px) {
    height: 1450px;

    &.second {
      height: 1200px;
    }
  }

  @media only screen and (max-width: 865px) {
    height: 2000px;

    *:first-child {
      /* margin-top: 3em; */
    }

    &:last-of-type,
    &:last-child,
    &.second {
      height: 600px;
      padding: 0;
    }
  }
`

const Home = styled.main`
  h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 22px;
  }

  .row {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin: 3rem 0;
  }

  @media only screen and (max-width: 576px) {
    .row {
      margin: 20px 0;
    }
  }
`
