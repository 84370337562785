import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import {
  Box,
  TextField,
  Popper,
  Paper,
  Fade,
  ClickAwayListener,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core"
import { FaAngleDown } from "react-icons/fa"
import { MdArrowDropDown } from "react-icons/md"
import { color, fontSize } from "../../../theme"
import { makeStyles } from "@material-ui/styles"
import CountInput from "./CountInput"

const useStyles = makeStyles({
  passenger: {
    display: "flex",
    width: "120px",
    margin: 0,
    justifyContent: "center",
  },
})

function FlightFilters({ onChange, initialValues, t }) {
  const [values, setValues] = useState({
    adults: initialValues.adults,
    children: initialValues.children,
    infants: initialValues.infants,
    oneWay: initialValues.oneWay,
    passengerClass: initialValues.passengerClass,
    type: initialValues.type,
    open: false,
    anchorEl: null,
    tab: 0,
  })

  const totalPassengers = values.adults + values.children + values.infants
  const passengers = `${totalPassengers} ${
    totalPassengers !== 1 ? t("passengers") : t("passenger")
  }`

  const handleClickAway = e => {
    if (e.target.tagName === "BODY") return
    if (e.target.className) {
      if (
        typeof e.target.className !== "object" &&
        e.target.className.search("Mui-selected") === -1
      ) {
        setValues({ ...values, open: false })
      }
    }
  }

  const handleToggle = event => {
    setValues({
      ...values,
      open: !values.open,
      anchorEl: event.currentTarget,
    })
  }

  const handleChange = event => {
    const { name, value } = event.target
    const { adults, children, infants, oneWay, passengerClass } = values
    setValues({ ...values, [name]: value })
    onChange({
      adults,
      children,
      infants,
      oneWay,
      passengerClass,
      [name]: value,
    })
  }

  const {
    adults,
    children,
    infants,
    oneWay,
    passengerClass,
    open,
    anchorEl,
    tab,
  } = values
  const classes = useStyles()

  return (
    <Container>
      <div className="label">{t("Passengers")}</div>
      <Button onClick={handleToggle}>
        <div>
          {adults + children} , {passengerClass === "Economy" && t("Economy")}
          {passengerClass === "PremiumEconomy" && t("Premium Economy")}
          {passengerClass === "Business" && t("Business")}
          {passengerClass === "First" && t("First Class")}
        </div>
        <MdArrowDropDown />
      </Button>
      <StyledPopper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className="passenger-popup">
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={passengerClass}
                    onChange={handleChange}
                    disableUnderline
                    width="50%"
                    inputProps={{
                      name: "passengerClass",
                      id: "passengerClass",
                    }}
                  >
                    <MenuItem value="Economy">{t("Economy")}</MenuItem>
                    <MenuItem value="PremiumEconomy">
                      {t("Premium Economy")}
                    </MenuItem>
                    <MenuItem value="Business">{t("Business")}</MenuItem>
                    <MenuItem value="First">{t("First Class")}</MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <CountInput
                    name="adults"
                    label={t("Adults")}
                    initialCount={adults}
                    min={1}
                    max={9 - children}
                    onChange={handleChange}
                    isHotel={false}
                  />
                  <CountInput
                    name="children"
                    label={t("Children")}
                    initialCount={children}
                    min={0}
                    max={9 - adults}
                    onChange={handleChange}
                    isHotel={false}
                  />
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </StyledPopper>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  .label {
    position: absolute;
    font-size: 14px;
    background-color: white;
    margin-left: 25px;
    margin-top: -8px;
    padding: 0 5px;
    color: rgb(0, 0, 0, 0.6);
  }
  @media only screen and (max-width: 865px) {
    width: 100%;
    margin-top: 20px;
  }
`

const Button = styled.div`
  border: 1px solid ${color.black12};
  border-radius: 4px;
  padding-left: 15px;
  color: ${color.black87};
  cursor: pointer;
  font-size: ${fontSize.body};
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  height: 48px;
  width: 207px;
  line-height: 48px;

  svg {
    color: ${color.black60};
    font-size: 30px;
    height: 48px;
    margin-right: 5px;
  }
  div {
    align-self: center;
    padding-top: 1px;
    width: 100%;
  }

  ${props =>
    props.ageIsInvalid === true &&
    css`
      border-color: ${color.error} !important;
    `};

  @media only screen and (max-width: 865px) {
    width: 100%;
    margin: 0;
  }
`

const StyledPopper = styled(Popper)`
  width: 300px;
  z-index: 4;
  .MuiPaper-root {
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
    padding: 20px 20px 10px 20px;
  }
  .MuiInputBase-root {
    margin-bottom: 20px;
  }
  .MuiInputBase-root:hover fieldset {
    border-color: rgba(0, 0, 0, 0.6);
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${color.tangerine};
  }
`

FlightFilters.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
}

export default FlightFilters
