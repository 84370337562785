import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist"

const httpLink = createHttpLink({
  uri: process.env.AUTOCOMPLETE_URL,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const SCHEMA_VERSION_KEY = "apollo-cache-age"

export const cache = new InMemoryCache()

export const client = new ApolloClient({
  ssrMode: typeof window === "undefined",
  cache,
  fetchOptions: {
    method: "GET",
  },
  link: httpLink.concat(errorLink),
})

async function setupApollo() {
  try {
    if (typeof window === "undefined") {
      const persistor = new persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      })

      // Read the current schema version from AsyncStorage.
      const currentVersion = localStorage.getItem(SCHEMA_VERSION_KEY)

      let fourDaysAgo = new Date()
      fourDaysAgo.setDate(fourDaysAgo.getDate() - 4)

      if (currentVersion > fourDaysAgo) {
        // If the current version matches the latest version,
        // we're good to go and can restore the cache.
        await persistor.restore()
      } else {
        // Otherwise, we'll want to purge the outdated persisted cache
        // and mark ourselves as having updated to the latest version.
        await persistor.purge()
        localStorage.setItem(SCHEMA_VERSION_KEY, new Date())
      }
    }
  } catch (error) {
    console.error("Error restoring Apollo cache", error)
  }
}

setupApollo()
