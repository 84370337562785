import { uniqBy } from "lodash"

class AutocompleteUtils {
  static isMobile() {
    const w = window
    const d = document
    const documentElement = d.documentElement
    const body = d.getElementsByTagName("body")[0]
    const width =
      w.innerWidth || documentElement.clientWidth || body.clientWidth

    const isMobile = width <= 768
    return isMobile
  }

  static sortFlightResults(value, results, initialResults = []) {
    const isMobile = this.isMobile()
    let finalResults = initialResults || []
    let rest = []

    if (results.airports && results.airports.length > 0) {
      finalResults.push(...results.airports)
    }

    if (results.cities && results.cities.length > 0) {
      if (finalResults.length > 3) finalResults = finalResults.slice(0, 3)
      finalResults.push(...results.cities)
    }

    if (results.places_suggestions && results.places_suggestions.length > 0) {
      if (finalResults.length > 4) finalResults = finalResults.slice(0, 3)
      finalResults.push(...results.places_suggestions)
    }

    finalResults = uniqBy(finalResults, "iata")
    return finalResults.slice(0, isMobile ? 5 : 8)
  }

  static sortHotelResults(results) {
    const isMobile = this.isMobile()
    let finalResults = []

    if (results.properties && results.properties.length > 0) {
      finalResults.push(...results.properties)
    }

    if (results.cities && results.cities.length > 0) {
      finalResults.push(...results.cities)
    }

    return finalResults.slice(0, isMobile ? 6 : 10)
  }
}

export default AutocompleteUtils
