import React, { useState } from "react"
import styled, { css } from "styled-components"
import { MdClose } from "react-icons/md"
import theme from "../../theme"

export default function Banner({ t }) {
  const [close, setClose] = useState(false)

  return (
    <Section close={close}>
      <div className="banner_container">
        {t(
          "Please note that we use cookies necessary for the functioning of our website, \
          cookies that optimize the performance. By using TravelByBit, you accept our use of cookies."
        )}
      </div>
      <Icon onClick={() => setClose(true)}>
        <MdClose />
      </Icon>
    </Section>
  )
}

const Section = styled.div`
  background-color: rgba(0, 0, 0, 0.87);
  color: #ee406b;
  padding: 15px 0;
  text-align: center;

  .banner_container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 3rem;
    font-size: 14px;

    @media only screen and (max-width: 500px) {
      padding: 0 3.5rem;
    }
    @media only screen and (max-width: 460px) {
      font-size: 12px;
    }
  }

  ${props =>
    props.close &&
    css`
      display: none;
    `}
`

const Icon = styled.div`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  color: #ee406b;
  font-size: 20px;
`
