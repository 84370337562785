import React, { useState } from "react"
import styled from "styled-components"
import SearchBox from "./search/searchBox"
import Header from "../header"
import Banner from "./banner"
import FlightBannerImage from "../../images/background/flight-bg.svg"
import HotelBannerImage from "../../images/background/hotel-bg.svg"

export default function Hero({ places, t }) {
  const [tripType, setTripType] = useState("flights")

  const changeType = (type) => {
    setTripType(type)
  }
  return (
    <HeroBox tripType={tripType}>
      <Banner t={t} />
      <div className="hero-image" />
      <Header hero={true} />
      {tripType === "flights" ? (
        <div className="container">
          <h1>{t("Travel the world")}</h1>
        </div>
      ) : (
        <div className="container">
          <h1>{t("Book Hotels on Travala.com")}</h1>
          <h3>{t("2,000,000+ Accomodations worldwide")}</h3>
        </div>
      )}
      }
      <SearchBox places={places} t={t} changeType={changeType} />
    </HeroBox>
  )
}

const HeroBox = styled.div`
  .hero-image {
    ${(props) =>
      props.tripType === "flights"
        ? `background-image: url(${FlightBannerImage});`
        : `background-image: url(${HotelBannerImage});`}
    position: absolute;
    width: 100%;
    background-size: cover;
    background-position: center center;
    height: 507px;
  }

  .container {
    width: 1200px;
    margin: 0 auto;
    position: relative;

    h1 {
      font-size: 64px;
      font-weight: 600;
      margin-top: 60px;
      color: white;
    }
    h3 {
      margin-top: 10px;
      font-size: 32px;
      color: white;
    }
  }

  .search {
    width: 1201px;
    height: 282px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  .dest {
    height: 123px;
    width: 360px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 1em;
  }

  @media only screen and (max-width: 1275px) {
    .hero-image {
      background-position: center center;
      height: 550px;
      margin-bottom: ;
    }

    .container {
      width: 850px;
    }
  }

  @media only screen and (max-width: 865px) {
    .hero-image {
      ${(props) =>
        props.tripType === "flights" ? `height: 630px;` : `height: 530px;`}
    }
    .container {
      width: 80%;

      h1 {
        margin-top: -10px;
        font-size: 30px;
      }
      h2,
      h3 {
        font-size: 22px;
      }
    }
  }

  @media only screen and (max-width: 865px) {
    .container {
      h1 {
        margin-top: -10px;
        font-size: 26px;
      }
      h3 {
        display: none;
      }
    }
  }
`
