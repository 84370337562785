import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { Button } from "@material-ui/core"
import { navigate } from "@reach/router"
import queryString from "query-string"
import moment from "moment"
import { FaExchangeAlt } from "react-icons/fa"
import {
  MdSearch,
  MdRadioButtonUnchecked,
  MdRadioButtonChecked,
} from "react-icons/md"
import DatePicker from "./DatePicker"
import FlightFilters from "./flightFilters"
import theme from "../../../theme"
import Autocomplete from "./locationAutocomplete"

export default function Flights({ t }) {
  const [destinations, setDestinations] = useState([])
  const [flightType, setFlightType] = useState("return")
  const [flightDetails, setFlightDetails] = useState({
    passengerClass: "Economy",
    flightType: "return",
    adults: 1,
    infants: 0,
    children: 0,
    oneWay: false,
    startDate: moment().add(4, "days"),
    from: "",
    to: "",
  })

  // Values for the corresponding input fields
  const [departValue, setDepartValue] = useState("")
  const [arriveValue, setArriveValue] = useState("")

  const [formErrors, setFormErrors] = useState({
    from: false,
    to: false,
    startDate: false,
    endDate: false,
    departValue: false,
    arriveValue: false,
  })

  // Validates departure and arrival destination fields
  async function validateFields(type) {
    const { from, to, startDate, endDate } = flightDetails
    const errors = {
      startDate: startDate === null,
    }

    switch (type) {
      case "from":
        errors.from = from === ""
        break
      case "to":
        errors.to = to === ""
        break
      case "dates":
        errors.endDate = !endDate
        break
      case "submit":
        errors.from = from === ""
        errors.to = to === ""
        errors.endDate = flightType === "return" && !endDate
        break
      default:
        console.warn("Can not evaluate property of type.")
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return errors
  }

  // Called when the x is clicked in the fields
  const clearField = (field) => {
    switch (field) {
      case "from":
        setDepartValue("")
        setFlightDetails({
          ...flightDetails,
          from: "",
        })
        setFormErrors({
          ...formErrors,
          [field]: false,
        })
        break
      case "to":
        setArriveValue("")
        setFlightDetails({
          ...flightDetails,
          to: "",
        })
        setFormErrors({
          ...formErrors,
          [field]: false,
        })
        break
    }
  }

  // Called when the switch arrows between fields is clicked
  const switchDestinations = () => {
    setDepartValue(arriveValue)
    setArriveValue(departValue)
    setFlightDetails({
      ...flightDetails,
      from: flightDetails.to,
      to: flightDetails.from,
    })
  }

  // Sets flight details and form errors when date is picked
  const onDateChange = (startDate, endDate) => {
    setFlightDetails({
      ...flightDetails,
      startDate: startDate,
      endDate: endDate,
    })

    setFormErrors({
      ...formErrors,
      endDate: false,
    })
  }

  const searchFlight = (e) => {
    e.preventDefault()
    const re = /\((.*)\)/i

    let searchBase = ""
    validateFields("submit").then((res) => {
      // First check for any errors
      let errors = Object.keys(res).some((k) => res[k])

      if (!errors) {
        const { from, to } = flightDetails

        // Build the URL
        searchBase = queryString.stringify({
          to,
          from,
          depart: flightDetails.startDate.format("YYYY-MM-DD"),
          return:
            flightType === "return"
              ? flightDetails.endDate.format("YYYY-MM-DD")
              : undefined,
          adults: flightDetails.adults,
          kids: flightDetails.children,
          infants: flightDetails.infants,
          class: flightDetails.passengerClass,
          sortBy: "best",
          bookingStep: "outbound",
        })
      } else {
        return
      }
      navigate(`${process.env.TRAVALA_URL}/flights/search?${searchBase}`)
    })
  }

  const onFilterChange = (inputs) => {
    setFlightDetails({
      ...flightDetails,
      ...inputs,
    })
  }

  // Runs whenever user enters or changes any inputs
  const onInputChange = (name, text, location) => {
    let value = ""
    if (location) {
      if (location.bbox) {
        value = location.bbox
      } else if (location.gds_code) {
        value = location.gds_code
      } else if (location.iata) {
        value = location.iata
      }
    }

    // get the name of the field to determine type of input
    let fieldName
    if (name === "origin") {
      setDepartValue(text)
      fieldName = "from"
    } else if (name === "destination") {
      setArriveValue(text)
      fieldName = "to"
    }

    // Check if they have entered or chosen a valid value
    if (location) {
      setFormErrors({
        ...formErrors,
        [fieldName]: false,
      })
    }
    setLocation(value, fieldName)
  }

  // Runs whenever user switches between return and single
  const handleFlightTypeChange = (type) => {
    setFlightType(type)
    setFormErrors({
      from: false,
      to: false,
      startDate: false,
      endDate: false,
    })
  }

  const handleOptionChange = (e) => {}

  const setLocation = (iata, fieldName) => {
    setFlightDetails({
      ...flightDetails,
      [fieldName]: iata,
    })
  }

  return (
    <FormArea>
      <Radio
        checked={flightType === "return"}
        onClick={() => setFlightType("return")}
      >
        <div className="checkmark" />
        {t("Return")}
      </Radio>
      <Radio
        checked={flightType === "single"}
        onClick={() => setFlightType("single")}
      >
        <div className="checkmark" />
        {t("One Way")}
      </Radio>
      <form autoComplete="off" onSubmit={searchFlight}>
        <input style={{ display: "none" }} type="text" name="fakeusername" />
        <input
          style={{ display: "none" }}
          type="password"
          name="fakepassword"
        />
        <div className="top-row">
          <div className="from customFieldBorders">
            <Autocomplete
              onValueChange={onInputChange}
              name="origin"
              value={departValue}
              isDeparture
              validation={formErrors.from}
              t={t}
            />
          </div>
          <FaExchangeAlt className="switch" onClick={switchDestinations} />
          <div className="to customFieldBorders">
            <Autocomplete
              onValueChange={onInputChange}
              name="destination"
              value={arriveValue}
              validation={formErrors.to}
              t={t}
            />
          </div>
        </div>
        <div className="options">
          <div className="date">
            <DatePicker
              flightType={flightType}
              onDateChange={onDateChange}
              startDate={flightDetails.startDate}
              endDate={flightDetails.endDate}
              formErrors={formErrors}
              validateFields={validateFields}
              t={t}
            />
          </div>
          <FlightFilters
            initialValues={flightDetails}
            onChange={onFilterChange}
            t={t}
          />
          <div className="search-button-container">
            <Button type="submit" variant="contained" className="search-button">
              <MdSearch />
              {t("Search Flights")}
            </Button>
          </div>
        </div>
      </form>
    </FormArea>
  )
}

const Radio = styled.label`
  display: -webkit-inline-box;
  margin: 0 20px 25px 0;
  cursor: pointer;

  .checkmark {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid grey;
    margin-right: 10px;
    position: relative;

    ${(props) =>
      props.checked &&
      css`
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          background-color: grey;
          border-radius: 50%;
          top: 3px;
          left: 3px;
        }
      `}
  }
`

const FormArea = styled.div`
  color: #333;

  * {
    font-family: ProximaNova;
  }

  .active {
    border-bottom: 2px solid #414042;
  }

  .MuiFormGroup-root {
    input {
      display: none;
    }
  }

  .hiddenRadiomark {
    .MuiIconButton-label {
      div {
        svg:nth-child(2) {
          transform: scale(0) !important;
        }
      }
    }
  }

  .shownRadiomark {
    .MuiIconButton-label {
      div {
        svg:nth-child(2) {
          transform: scale(1) !important;
        }
      }
    }
  }

  .trip-type {
    margin-bottom: 40px;

    span {
      margin-right: 68px;
      color: #414042;

      &:hover {
        cursor: pointer;
      }
    }
  }
  form {
    display: flex;
    flex-direction: row;
  }

  .top-row {
    display: flex;
    align-items: center;
  }

  .switch {
    color: rgb(0, 0, 0, 0.6);
    margin: 0 10px;
    grid-area: switch;
    font-size: 18px;

    &:hover {
      color: ${theme.orange};
      cursor: pointer;
    }
  }

  .options {
    display: flex;
    align-items: center;
    color: #414042;

    .search-button {
      width: 216px;
      height: 48px;
      border-radius: 8px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background-color: #f66415;
      margin-left: 16px;
      text-align: center;
      color: white;
      font-size: 20px;
      font-family: ProximaNova;
      text-transform: capitalize;
      cursor: pointer;

      svg {
        font-size: 29px;
        margin-right: 10px;
        vertical-align: middle;
        height: 100%;
      }
    }
  }

  .date {
    z-index: 9;
  }

  @media only screen and (max-width: 1275px) {
    .top-row {
      margin-bottom: 30px;
    }
    form {
      flex-direction: column;
      .from,
      .to {
        flex-grow: 1;
      }
    }
    .search-button-container {
      flex-grow: 1;
      .search-button {
        float: right;
      }
    }
  }

  @media only screen and (max-width: 865px) {
    .top-row {
      flex-wrap: wrap;
      margin-bottom: 20px;
      .from {
        width: 70%;
      }
      .to {
        margin-top: 20px;
      }
    }

    .options {
      flex-direction: column;
      .date {
        width: 100%;
      }

      .search-button-container {
        margin-top: 30px;
        width: 100%;
        .search-button {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`
