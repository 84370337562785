import { gql } from "@apollo/client"

export const FLIGHTS_QUERY = gql`
  query Flights($query1: String!) {
    cities(query: $query1, limit: 5) {
      name
      iata
      country
      airports {
        id
        name
        iata
        city_code
      }
    }
    airports(query: $query1, limit: 4) {
      id
      name
      iata
      city_code
      city_name
      country
    }
  }
`

export const FLIGHTS_QUERY_TWO = gql`
  query Flights($query1: String!) {
    places_suggestions(query: $query1, airport: true) {
      id
      place_name
      nearby
    }
  }
`
