import React, { useState, useEffect } from "react"
import { DateRangePicker, SingleDatePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import styled from "styled-components"
import { colours } from "../../../theme"
import moment from "moment"

const IconSeperatorContainer = styled.div`
  position: relative;
  height: 10px;
  width: 15px;
`
const IconSeperator = styled.span`
  font-weight: 900;
  margin-top: -10px;
  position: absolute !important ;
  top: 0 !important ;
  bottom: 0 !important ;
`

export default function DatePicker({
  isHotel,
  flightType,
  onDateChange,
  startDate,
  endDate,
  formErrors,
  t,
}) {
  const [focusedInput, setFocusedInput] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [maxDate, setMaxDate] = useState(false)

  useEffect(() => {
    // check the window width and set mobile
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth < 800)
    )
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth

    if (width < 890) setIsMobile(true)
  }, [])

  const isOutsideRange = (day) => {
    let isOutsideRange = false
    if (moment().add(1, "days").isAfter(moment(day))) {
      isOutsideRange = true
    } else if (!isHotel && moment().add(1, "y").isBefore(moment(day))) {
      isOutsideRange = true
    } else if (
      startDate &&
      maxDate &&
      day.isAfter(startDate.clone().add(30, "days"))
    ) {
      isOutsideRange = true
    }
    return isOutsideRange
  }

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput)
    if (focusedInput === "startDate") {
      setMaxDate(false)
    } else if (isHotel) {
      setMaxDate(true)
      if (endDate && endDate.diff(startDate, "days") > 30) {
        onDateChange(startDate, null)
      }
    }
  }

  return (
    <Group>
      <div className="label">{t("Dates")}</div>
      <Container
        full={flightType === "return" || isHotel}
        error={formErrors.endDate}
        isHotel={isHotel}
      >
        {flightType === "return" || isHotel ? (
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onDatesChange={({ startDate, endDate }) =>
              onDateChange(startDate, endDate)
            }
            isOutsideRange={isOutsideRange}
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            orientation={isMobile ? "vertical" : "horizontal"}
            minimumNights={0}
            displayFormat="ddd DD/MM"
            customArrowIcon={
              <IconSeperatorContainer>
                <IconSeperator>_</IconSeperator>
              </IconSeperatorContainer>
            }
          />
        ) : (
          <SingleDatePicker
            date={startDate}
            focused={focusedInput}
            onDateChange={onDateChange}
            onFocusChange={({ focused }) => setFocusedInput(focused)}
            isOutsideRange={isOutsideRange}
            numberOfMonths={1}
            placeholder="Depart"
            orientation={isMobile ? "vertical" : "horizontal"}
            minimumNights={isHotel ? 1 : 0}
            displayFormat="ddd DD/MM"
          />
        )}
      </Container>
      {/* {formErrors.endDate && <Error>Please check your dates.</Error>} */}
    </Group>
  )
}

const Group = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .label {
    position: absolute;
    font-size: 14px;
    background-color: white;
    margin-left: 25px;
    margin-top: -8px;
    padding: 0 5px;
    color: rgb(0, 0, 0, 0.6);
    z-index: 1;
  }
`

const Error = styled.p`
  color: #d8000c;
  position: absolute;
  font-size: 14px;
  margin-top: 4em;
  margin-left: 20px;

  @media only screen and (max-width: 1219px) {
    margin-left: 0;

    &:last-child {
      margin-top: 5em;
    }
  }

  @media only screen and (max-width: 865px) {
    margin-top: 7.5em;

    &:last-child {
      margin-top: 4em;
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-left: 16px;
  height: 50px;
  font-size: 16px;
  width: 215px;

  ${(props) => props.error && `border: 1px solid #D8000C;`}

  @media only screen and (max-width: 1275px) {
    margin-left: 0;
  }

  .DateInput {
    width: 80px;
    input {
      font-size: 16px;
      padding: 13px 0 9px 0;
      letter-spacing: 0.5px;
      color: rgb(0, 0, 0, 0.87);
    }
    .DateInput_input__focused {
      border-color: #f66415;
    }
    &:first-child {
      margin-left: 13px;
    }
  }

  .DateRangePickerInput_arrow {
    margin: 0 7px 0 3px;
    svg {
      color: rgb(0, 0, 0, 0.6);
      width: 20px;
      height: 20px;
    }
  }

  .SingleDatePickerInput,
  .DateRangePickerInput {
    border: none;
    cursor: pointer;

    &:hover {
      cursor: pointer;
    }
  }

  .CalendarDay {
    border: none;
  }

  .SingleDatePicker,
  .SingleDatePicker__input,
  .DateRangePicker,
  .DateRangePicker__input {
    font-size: 16px;
  }

  .CalendarDay:hover {
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
  }

  .CalendarDay__selected_span {
    background: ${colours.lightorange};
    color: white;
    border: 1px solid ${colours.orange};
  }

  .CalendarDay__selected {
    background: ${colours.darkorange};
    color: white;
  }

  .CalendarDay__selected_end {
    border-radius: 0 0.375rem 0.375rem 0 !important;
  }

  .CalendarDay__selected_start {
    border-radius: 0.375rem 0 0 0.375rem !important;
  }

  .CalendarDay__selected:hover {
    background: ${colours.darkorange};
    color: white;
  }

  .CalendarDay__selected_span:hover {
    background: ${colours.orange};
    color: white;
    border: 1px solid ${colours.orange};
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: darkorange;
    color: white;
    border: 1px solid ${colours.darkorange};
    border-radius: 0 !important;
  }

  @media only screen and (max-width: 865px) {
    width: 100%;

    .DateRangePicker__picker {
      left: 0;
      top: 80px;
    }

    .DateInput,
    .DateInput__1 {
      font-size: 16px;
    }

    .SingleDatePickerInput,
    .DateRangePickerInput,
    .CalendarDay {
      border: none;
    }
  }

  @media only screen and (max-width: 400px) {
    .SingleDatePicker_picker,
    .DateRangePicker_picker {
      margin-left: -20px;
    }
  }
`
