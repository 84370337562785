import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Button } from "@material-ui/core"
import theme from "../../theme"
import { useTranslation } from "react-i18next"

export default function Warning() {
  const { t } = useTranslation()
  return (
    <WarningBox>
      <div className="container">
        <h3>{t("COVID-19 Impact/Urgent Travel Advice")}</h3>
        <h4>
          Due to the continuing coronavirus crisis and government restrictions,
          the airlines have made significant changes to their flight schedules
          and numerous flight connections on our website are currently
          unavailable. Refer to government guidelines and{" "}
          <a href="https://www.who.int/">WHO</a> for the latest updates related
          to COVID-19.
        </h4>
        <h4>
          Most airports around the world are imposing new government
          restrictions and quarantine rules without prior notice. Please check
          the stopover airport you are transiting for the latest information in
          regards to restrictions and closures. It is likely and possible you
          may be denied boarding if your passport/nationality doesn't meet the
          requirements of the transiting countries. Airlines are not offering
          refunds for this change in policy, only credit, and may not have
          alternative arrangements for your onward travel.
        </h4>
      </div>
    </WarningBox>
  )
}

const WarningBox = styled.div`
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #f66;
  background-image: linear-gradient(to right, #f6d8db 2%, #fcfeff 103%);

  @media only screen and (max-width: 1275px) {
    width: 90%;
  }

  .container {
    padding: 48px 40px;

    h3 {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 20px;
    }

    h4 {
      font-size: 18px;
      font-weight: normal;
    }

    a {
      text-decoration: none;
    }

    .MuiButton-contained {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background: #fff;
      margin-top: 30px;
      border-radius: 8px;
      padding: 7px 20px;
      .MuiButton-label {
        font-size: 16px;
        color: #f66415;
        text-transform: none;
      }
    }

    @media only screen and (max-width: 450px) {
      padding: 24px;
      h3 {
        font-size: 22px;
      }
      h4 {
        font-size: 16px;
      }
    }
`
