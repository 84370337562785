import React, { useContext } from "react"
import UserContext from "../userContext"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

export default function Destination({ t }) {
  const { userData } = useContext(UserContext)

  let staticQuery = graphql`
    {
      allContentfulDestination {
        edges {
          node {
            title
            slug
            heroImage {
              fluid(maxWidth: 360, quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={`${staticQuery}`}
      render={(data) => (
        <Section>
          <h2>{t("Explore destinations")}</h2>
          <div className="destinations">
            {data.allContentfulDestination.edges.map(({ node }, i) => {
              return (
                <Link to={"/flightsto" + node.slug} key={i}>
                  <Card>
                    <div className="image">
                      <Img
                        fluid={node.heroImage && node.heroImage.fluid}
                        style={{
                          height: "100%",
                          width: "100%",
                          filter: "opacity(75%)",
                        }}
                        objectFit="cover"
                      />
                    </div>
                    <div className="mask">
                      <h4>{t(node.title)}</h4>
                    </div>
                  </Card>
                </Link>
              )
            })}
          </div>
        </Section>
      )}
    />
  )
}

const Section = styled.section`
  width: 1200px;
  margin: 0 auto;
  h2 {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 1275px) {
    width: 90%;
  }
`

const Card = styled.div`
  height: 223px;
  width: 284px;
  margin-right: 20px;
  border-radius: 16px;
  position: relative;
  display: inline-block;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0px 5px 12px 2px rgba(0, 0, 0, 0.32);
  }
  @media only screen and (max-width: 680px) {
    width: 100%;
    margin: 0 0 15px 0;
  }

  .mask {
    position: absolute;
    border-radius: 16px;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(84, 84, 84, 0) 30%,
      rgba(0, 0, 0, 0.87) 100%
    );
    h4 {
      font-size: 22px;
      color: #fff;
      position: relative;
      top: 175px;
      padding-left: 25px;
      font-weight: normal;
    }
  }
  .image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

    img {
      border-radius: 16px;
    }
  }
`
