import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Coins({ t }) {
  let staticQuery = graphql`
    query PaymentImage {
      banner: file(relativePath: { regex: "/Payment-illo.jpg/" }) {
        childImageSharp {
          fixed(width: 450, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={`${staticQuery}`}
      render={(data) => (
        <Section>
          <div className="container"></div>
        </Section>
      )}
    />
  )
}

const Section = styled.section`
  .container {
    width: 1200px;
    margin: 150px auto 120px auto;
    @media only screen and (max-width: 1275px) {
      width: 90%;
      div:first-child {
        width: 40% !important;
      }
      .content {
        margin-left: 5% !important;
      }
    }
    @media only screen and (max-width: 760px) {
      margin: 100px auto 120px auto;
      text-align: center;
      div:first-child {
        width: 450px !important;
        margin-bottom: 20px;
      }
      .content {
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
    @media only screen and (max-width: 500px) {
      div:first-child {
        width: 90% !important;
      }
    }

    div {
      display: inline-block;
      vertical-align: middle;
    }

    .content {
      width: 50%;
      margin-left: 120px;
      h2 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      p {
        font-size: 18px;
        line-height: 28px;
      }
      @media only screen and (max-width: 450px) {
        h2 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
`
