import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import {
  Box,
  TextField,
  Popper,
  Paper,
  Fade,
  ClickAwayListener,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import CountInput from "./CountInput"
import { FiPlus, FiMinus, FiXCircle } from "react-icons/fi"
import { FaAngleDown } from "react-icons/fa"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import { makeStyles } from "@material-ui/styles"
import RenderChildren from "./ChildAges"
import { color, fontSize } from "../../../theme"
import { Trans } from "react-i18next"

const useStyles = makeStyles({
  passenger: {
    display: "flex",
    width: "100px",
    margin: 0,
  },
})

function HotelFilter({ onChange, initialValues, ageIsInvalid, t }) {
  const [filters, setFilters] = useState({
    adults_0: initialValues.adults,
    children_0: initialValues.children,
    rooms: initialValues.rooms,
    open: false,
    anchorEl: null,
    childrenAge_0: initialValues.childrenAge,
  })
  const { adults_0, children_0, rooms, open, anchorEl } = filters

  // Increases/decreases number of adults and rooms
  const handleFilterChange = (e) => {
    const { name, value } = e.target

    setFilters({
      ...filters,
      [name]: value,
    })
  }

  // Increases/decreases number of children
  const handleChildrenFilterChange = (e) => {
    const { name, value } = e.target

    let childrenAge = [
      ...filters[`childrenAge_${name.replace("children_", "")}`],
    ]

    // Check if they are adding or removing children
    value >= childrenAge.length
      ? childrenAge.push({ id: value, age: null, isInvalid: false })
      : childrenAge.pop()

    setFilters({
      ...filters,
      [name]: value,
      [`childrenAge_${name.replace("children_", "")}`]: childrenAge,
    })
  }

  // Whenever the filters are changed called the onChange method and pass the new filters
  useEffect(() => {
    onChange(filters)
  }, [filters])

  // Used for the 1 Traveller string
  const totalPeople = [...Array(filters.rooms)]
    .map((n, i) => {
      return filters[`adults_${i}`]
    })
    .reduce((a, b) => a + b, 0)

  const totalChilds = [...Array(filters.rooms)]
    .map((n, i) => {
      return filters[`childrenAge_${i}`].length
    })
    .reduce((a, b) => a + b, 0)

  // Toggles filters open
  const handleToggle = (event) => {
    setFilters({
      ...filters,
      open: !filters.open,
      anchorEl: event.currentTarget,
    })
  }

  const addRoom = () => {
    setFilters({
      ...filters,
      rooms: rooms + 1,
      [`adults_${rooms}`]: 1,
      [`children_${rooms}`]: 0,
      [`childrenAge_${rooms}`]: [],
    })
  }

  const removeRoom = (i) => {
    setFilters({
      ...filters,
      rooms: rooms - 1,
      [`adults_${rooms}`]: 0,
      [`childrenAge_${rooms}`]: 0,
      [`childrenAge_${rooms}`]: [],
    })
  }

  // Closes filters when unfocused
  const handleClickAway = (e) => {
    if (e.target.tagName === "BODY") return
    if (
      e.target.className &&
      typeof e.target.className === "string" &&
      e.target.className.search("Mui-selected") === -1
    ) {
      setFilters({
        ...filters,
        open: false,
      })
    } else {
      setFilters({
        ...filters,
        open: true,
      })
    }
  }

  const selectAge = (newChildren) => {
    let updated = filters[`childrenAge_${newChildren.index}`]
    updated[newChildren.id - 1] = newChildren
    setFilters({
      ...filters,
      [`childrenAge_${newChildren.index}`]: updated,
    })
  }

  const roomPaxes = (i) => (
    <React.Fragment>
      <div className="room">
        <div className="number">
          {`Room ${i + 1}`}
          {i > 0 && (
            <IconButton
              onClick={() => removeRoom(i)}
              style={{ display: "flex", margin: "0 auto" }}
            >
              <FiXCircle style={{ color: color.black38 }} />
            </IconButton>
          )}
        </div>
        <div className="selection">
          <CountInput
            name={`adults_${i}`}
            label={t("Adults")}
            initialCount={filters[`adults_${i}`]}
            min={1}
            max={4}
            onChange={handleFilterChange}
            isHotel={true}
          />
          <CountInput
            name={`children_${i}`}
            label={t("Children")}
            initialCount={filters[`children_${i}`]}
            min={0}
            max={4}
            onChange={handleChildrenFilterChange}
            isHotel={true}
          />
          <div style={{ display: "flex" }}>
            {filters[`childrenAge_${i}`].map((child) => (
              <RenderChildren
                child={child}
                index={i}
                selectAge={selectAge}
                ageIsInvalid={ageIsInvalid}
                key={child.id}
              />
            ))}
          </div>
        </div>
      </div>
      {i < rooms - 1 && <hr />}
    </React.Fragment>
  )

  const classes = useStyles()

  return (
    <Container>
      <div className="label">{t("Travellers")}</div>
      <Button onClick={handleToggle} ageIsInvalid={ageIsInvalid}>
        <div>
          {totalPeople}{" "}
          {totalPeople < 2 ? (
            <Trans i18nKey="amount_adult">Adult</Trans>
          ) : (
            <Trans i18nKey="amount_adults">Adults</Trans>
          )}
          {totalChilds > 0 ? `, ${totalChilds} Children` : ""}
        </div>
        <ArrowDropDown />
      </Button>
      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        transition="transition"
        disablePortal="disablePortal"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className="passenger-popup" elevation={2}>
                {[...Array(rooms)].map((n, i) => roomPaxes(i))}
                <StyledButton
                  disabled={rooms > 3}
                  onClick={() => rooms < 4 && addRoom()}
                >
                  <FiPlus /> Add room
                </StyledButton>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </StyledPopper>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  hr {
    color: rgb(0, 0, 0, 0.4);
    margin: 8px 0;
  }

  .room {
    display: flex;
    flex-direction: row;
    width: 100%;
    .number {
      width: 66px;
      min-width: 66px;
      text-align: center;
    }
    .selection {
      flex-grow: 1;
      flex-direction: column;
    }
  }

  .label {
    position: absolute;
    font-size: 14px;
    background-color: white;
    margin-left: 25px;
    margin-top: -8px;
    padding: 0 5px;
    color: rgb(0, 0, 0, 0.6);
  }
  @media only screen and (max-width: 865px) {
    width: 100%;
    margin-top: 20px;
  }
`

const Button = styled.div`
  border: 1px solid ${color.black12};
  border-radius: 4px;
  padding-left: 15px;
  color: ${color.black87};
  cursor: pointer;
  font-size: ${fontSize.body};
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  height: 48px;
  width: 207px;
  line-height: 48px;

  svg {
    color: ${color.black60};
    font-size: 30px;
    height: 48px;
    margin-right: 5px;
  }
  div {
    align-self: center;
    padding-top: 1px;
    width: 100%;
  }

  ${(props) =>
    props.ageIsInvalid === true &&
    css`
      border-color: ${color.error} !important;
    `};

  @media only screen and (max-width: 865px) {
    width: 100%;
    margin: 0;
  }
`

const StyledButton = styled.div`
  border: 1px solid #f66415 !important;
  padding-left: 25px;
  color: #f66415;
  position: relative;
  padding-bottom: 5px;
  padding-top: 5px;
  align-items: center;
  width: 150px;
  height: 36px;
  border-radius: 22px;
  font-size: 18px;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  svg {
    font-size: 16px !important;
    margin-right: 4px;
  }

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${color.black12} !important;
      color: ${color.black12};

      svg {
        color: ${color.black12};
      }
    `}
`

const StyledPopper = styled(Popper)`
  width: 338px;
  z-index: 5;

  .MuiPaper-root {
    padding: 20px 20px 10px 20px;
  }
  .MuiPaper-root {
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
    padding: 20px 20px 10px 20px;
  }
  .MuiInputBase-root {
    height: 30px;
  }
  .MuiInputBase-root:hover fieldset {
    border-color: rgba(0, 0, 0, 0.6);
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${color.tangerine};
  }
`

HotelFilter.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
}

export default HotelFilter
