import React from "react"
import {
  MdRoom,
  MdHotel,
  MdLocationCity,
  MdLocationOn,
  MdPlace,
  MdFlag,
  MdAirplanemodeActive,
} from "react-icons/md"
import {
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ClickAwayListener,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import styled, { css } from "styled-components"
import { debounce } from "throttle-debounce"
import axios from "axios"

import star from "../../../images/icons/star.png"
import starb from "../../../images/icons/starb.png"
import { color, fontSize, shadows } from "../../../theme"
import { FLIGHTS_QUERY, FLIGHTS_QUERY_TWO } from "./graphqlQueries"
import AutocompleteUtils from "./AutocompleteUtils"
import { client, cache } from "./autocompleteClient"

const travala = axios.create({
  baseURL: process.env.TRAVALA_API_URL,
})

class LocationAutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      single: this.props.value || "",
      suggestions: [],
      open: false,
      airports: [],
      isFetching: false,
      noResult: false,
      focus: false,
    }

    this.fetchSuggestions = debounce(200, this.fetchSuggestions)
  }

  componentDidMount() {
    if (this.props.searchValue) {
      const value = this.props.searchValue
      this.handleSuggestionsFetchRequested({ value })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.suggestions.length > 0 && this.state.single.length === 0) {
      this.setState({ suggestions: [] })
    }
    if (prevProps.value !== this.props.value) {
      this.props.value && this.setState({ single: this.props.value })
    }
  }

  fetchSuggestions = async (value) => {
    this.setState({ noResult: false, isFetching: true, open: true })
    let results = []

    try {
      if (!this.props.isHotel) {
        results = await this.fetchFlightSuggestions(value)
      } else {
        results = await this.fetchHotelSuggestions(value)
      }

      this.setState({ suggestions: results, isFetching: false })
    } catch (e) {
      console.error(e)
    }
  }

  fetchFlightSuggestions = async (value) => {
    let allResults = []

    try {
      const results = await client.query({
        query: FLIGHTS_QUERY,
        variables: {
          query1: value,
        },
      })

      allResults = AutocompleteUtils.sortFlightResults(value, results.data)

      allResults.some(
        (item) =>
          item.iata === value.toUpperCase() &&
          allResults.splice(item, 1) &&
          allResults.unshift(item)
      )
      if (value.length >= 2 && allResults.length <= 3) {
        const places = await client.query({
          query: FLIGHTS_QUERY_TWO,
          variables: {
            query1: value,
          },
        })

        allResults = AutocompleteUtils.sortFlightResults(
          value,
          places.data,
          allResults
        )
      }

      return allResults
    } catch (e) {
      console.error(e)
      return []
    }
  }

  fetchHotelSuggestions = async (value) => {
    let allResults = []

    try {
      let results = await travala.get("/suggestion/autocomplete", {
        params: { q: value },
      })

      return results.data.data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    if (value !== "" && value.length > 2) {
      this.fetchSuggestions(value)
    }
  }

  renderHotelSuggestion = (suggestion) => {
    const isHotelSugg = Boolean(suggestion.code)

    let length = AutocompleteUtils.isMobile() ? 60 : 45,
      suggestionType = "City",
      icon = <MdLocationCity />
    let text = isHotelSugg
      ? `${suggestion.name}, ${suggestion.city_name}, ${suggestion.country_name}`
      : suggestion.accent_name

    text = text.length > length ? text.substring(0, length - 3) + "..." : text

    if (!isHotelSugg) {
      let landtypes = [
        "establishment",
        "general_contractor",
        "point_of_interest",
        "route",
        "street_address",
        "street_number",
        "address",
      ]

      if (suggestion.types.includes("country")) {
        suggestionType = "Country"
        icon = <MdFlag />
      } else if (suggestion.types.some((r) => landtypes.includes(r))) {
        suggestionType = "Landmark"
        icon = <MdPlace />
      }
    } else {
      icon = <MdHotel />
    }

    return (
      <>
        <Suggestion
          onClick={(e) => {
            this.handleOnClick(e, suggestion)
          }}
          isHotel={true}
        >
          <div className="icon">{icon}</div>
          <div className="text hotel">
            <span>{text}</span>
            {!isHotelSugg && <div className="type">{suggestionType}</div>}
            {isHotelSugg &&
            suggestion.star_rate &&
            suggestion.star_rate !== 0 ? (
              <div className="stars">
                {[...Array(suggestion.star_rate)].map((n, i) => (
                  <img className="startImage" src={star} />
                ))}
                {[...Array(5 - suggestion.star_rate)].map((n, i) => (
                  <img className="startImage" src={starb} />
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </Suggestion>
      </>
    )
  }

  renderFlightSuggestion = (suggestion) => {
    const isAirport = Boolean(suggestion.city_code)
    const isPlace = Boolean(suggestion.place_name)

    return (
      <>
        {isAirport ||
        (suggestion.airports && suggestion.airports.length < 1) ? (
          <Suggestion
            onClick={(e) => {
              this.handleOnClick(e, suggestion)
            }}
          >
            <div className="icon">
              {isAirport ? (
                <MdAirplanemodeActive />
              ) : isPlace ? (
                <MdLocationOn />
              ) : (
                <MdLocationCity />
              )}
            </div>
            <div className="text">
              {suggestion.name}, {suggestion.country}
            </div>
            <div className="code">
              {isAirport ? suggestion.iata : "All airports"}
            </div>
          </Suggestion>
        ) : (
          <StyledAccordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Suggestion
                onClick={(e) => {
                  this.handleOnClick(e, suggestion)
                }}
              >
                <div className="icon">
                  {isAirport ? (
                    <MdAirplanemodeActive />
                  ) : isPlace ? (
                    <MdLocationOn />
                  ) : (
                    <MdLocationCity />
                  )}
                </div>
                <div className="text">
                  {`${
                    isPlace
                      ? suggestion.place_name
                      : suggestion.name + ", " + suggestion.country
                  }`}
                </div>
                <div className="code">
                  {isAirport ? suggestion.iata : isPlace ? "" : "All airports"}
                </div>
              </Suggestion>
            </AccordionSummary>
            <AccordionDetails>
              <AllAirports>
                {isPlace && (
                  <div className="title">
                    Matching airports for{` `}
                    {suggestion.place_name}
                  </div>
                )}

                {suggestion.airports &&
                  suggestion.airports.length > 0 &&
                  suggestion.airports.map((airport, i) => (
                    <React.Fragment key={i}>
                      {airport.city_code && (
                        <Suggestion
                          onClick={(e) => {
                            this.handleOnClick(e, airport)
                          }}
                          className="suggestion"
                        >
                          <div className="icon">
                            <MdAirplanemodeActive />
                          </div>
                          <div className="text">
                            {airport.name}, {suggestion.country}
                          </div>
                          <div className="code">{airport.iata}</div>
                        </Suggestion>
                      )}
                    </React.Fragment>
                  ))}
                {isPlace &&
                  suggestion.nearby &&
                  suggestion.nearby.map((airport, i) => (
                    <React.Fragment key={i}>
                      {airport.city_code && (
                        <Suggestion
                          onClick={(e) => {
                            this.handleOnClick(e, airport)
                          }}
                          className="suggestion"
                        >
                          <div className="icon">
                            <MdAirplanemodeActive />
                          </div>
                          <div className="text">
                            {airport.name}, {suggestion.country}
                          </div>
                          <div className="code">
                            {`${airport.iata} - ${airport.distance}km away`}
                          </div>
                        </Suggestion>
                      )}
                    </React.Fragment>
                  ))}
              </AllAirports>
            </AccordionDetails>
          </StyledAccordion>
        )}
      </>
    )
  }

  handleOnClick = (e, suggestion) => {
    e.stopPropagation()

    let name = ""
    if (this.props.isHotel) {
      const isHotelSugg = Boolean(suggestion.code)

      name = isHotelSugg
        ? `${suggestion.name}, ${suggestion.city_name}, ${suggestion.country_name}`
        : suggestion.accent_name
    } else {
      const isPlace = Boolean(suggestion.place_name)
      if (isPlace) suggestion = suggestion.nearby[0]
      name = `${suggestion.name} (${suggestion.iata})`
    }

    this.props.onValueChange(this.props.name, name, suggestion)
    this.setState({ single: name, open: false })
  }

  handleChange = (e) => {
    this.setState({ single: e.target.value })
    this.props.onValueChange(this.props.name, null, null)

    if (e.target.value.length === 0) {
      this.setState({ suggestions: [], open: false })
    } else if (e.target.value.length > 2) {
      this.fetchSuggestions(e.target.value)
    }
  }

  handleClickAway = (e) => {
    if (!e.target.name || e.target.name.search(this.props.name) === -1) {
      this.setState({ open: false })
    }
  }

  handleOnFocus = () => {
    this.setState({ focus: true })
    if (this.state.single.length > 0) {
      this.setState({ open: true })
    }
  }

  render() {
    const { isHotel, name, validation, t } = this.props

    const { single, suggestions, open, isFetching, focus } = this.state

    return (
      <Container isHotel={Boolean(isHotel)} name={name}>
        <InputLabel focus={focus}>
          {isHotel ? t("Destination") : name === "origin" ? t("From") : t("To")}
        </InputLabel>
        <Input
          placeholder={
            isHotel ? t("City, Place or Hotel") : t("City or Airport")
          }
          isHotel={Boolean(isHotel)}
          value={single}
          onChange={this.handleChange}
          name={name}
          autoComplete="off"
          aria-autocomplete="list"
          onFocus={this.handleOnFocus}
          onBlur={() => this.setState({ focus: false })}
          error={validation}
        />
        <SuggestionList open={open} name={name} isHotel={Boolean(isHotel)}>
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className="suggestion-list">
              {isFetching ? (
                <StyledLinearProgress size={20} margin="15px 0 13px 0" />
              ) : (
                <React.Fragment>
                  {suggestions && suggestions !== [] ? (
                    !isHotel ? (
                      suggestions.map((suggestion, i) => (
                        <React.Fragment key={i}>
                          {this.renderFlightSuggestion(suggestion)}
                        </React.Fragment>
                      ))
                    ) : (
                      <React.Fragment>
                        <p>Destinations</p>
                        {suggestions.cities &&
                          suggestions.cities.map((suggestion, i) => (
                            <React.Fragment key={i}>
                              {this.renderHotelSuggestion(suggestion)}
                            </React.Fragment>
                          ))}
                        <p>Properties</p>
                        {suggestions.properties &&
                          suggestions.properties.map((suggestion, i) => (
                            <React.Fragment key={i}>
                              {this.renderHotelSuggestion(suggestion)}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    )
                  ) : (
                    <p className="noResult">
                      We couldn't find any suggestions for you. <br />
                      Please try to enter a 3 letter code.
                    </p>
                  )}
                </React.Fragment>
              )}
            </div>
          </ClickAwayListener>
        </SuggestionList>
      </Container>
    )
  }
}

export default LocationAutoComplete

const Container = styled.div`
  display: flex;
  position: relative;

  .suggestion-list {
    p {
      text-align: left;
      padding: 6px;
      color: #fff;
      background-color: ${color.tangerine};
    }

    .noResult {
      font-size: ${fontSize.small};
      margin: 15px;
      color: #ef6c00;
      background-color: #fff;
    }
  }
  @media (max-width: 865px) {
    width: 100%;
  }
`

const InputLabel = styled.div`
  position: absolute;
  font-size: 14px;
  background-color: white;
  margin-left: 10px;
  margin-top: -8px;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.6);
  z-index: 1;

  ${(props) =>
    props.focus &&
    css`
      color: ${color.tangerine};
    `}
`

const Input = styled.input`
  border-radius: 4px;
  width: 213px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 48px;
  font-size: 16px;
  padding-left: 15px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.35);
  }
  &:focus {
    border: 2px solid ${color.tangerine};
    padding-left: 14px;
  }

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${color.error};
    `}

  ${(props) =>
    !props.isHotel &&
    css`
      @media (max-width: 1275px) {
        width: 100%;
      }
      @media (max-width: 865px) {
        width: 100%;
      }
    `}

  ${(props) =>
    props.isHotel === true &&
    css`
      width: 467px;
      @media (max-width: 1219px) {
        width: 26em;
      }
      @media (max-width: 865px) {
        width: 100%;
      }
    `}
`

const Spinner = styled(CircularProgress)`
  color: ${color.tangerine} !important;
  margin: 15px 0 13px 0 !important;
  text-align: center !important;
  svg {
    font-size: 15px !important;
  }
`

const StyledLinearProgress = styled(LinearProgress)`
  color: ${color.tangerine};
`

const SuggestionList = styled.div`
  position: absolute;
  z-index: 100;
  background: ${color.white};
  border-radius: 5px;
  box-shadow: ${shadows.normal};
  overflow: hidden;
  width: 415px;
  top: 50px;
  min-height: 30px;
  text-align: center;

  ${(props) =>
    props.open === false &&
    css`
      display: none;
    `}

  ${(props) =>
    props.isHotel === true &&
    css`
      width: 100%;
    `}

  @media(max-width: 865px) {
    width: 100%;
  }
  @media (max-width: 420px) {
    left: -15%;
    width: 90vw;
  }
`

const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  &.Mui-expanded {
    margin: 0 !important;
  }
  &::before {
    background-color: unset !important;
  }
  .MuiAccordionSummary-root {
    padding: 0 10px 0 0;
    cursor: pointer;
    &:hover {
      background: ${color.bgGrey};
    }
    &.Mui-expanded {
      margin: 0;
      min-height: unset;
    }
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
    max-width: calc(100% - 30px);
    &.Mui-expanded {
      margin: 8px 0;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
`

const Suggestion = styled.div`
  padding: 8px 4px 8px 14px;
  font-size: ${fontSize.body};
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;

  .icon {
    font-size: 22px;
    margin-right: 8px;
    color: ${color.black60};
  }
  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    span {
      flex-grow: 1;
      max-width: 75%;
    }
    &.hotel {
      display: flex;
      width: 100%;

      .type {
        margin-left: auto;
        border: 1px solid;
        border-radius: 24px;
        font-size: 14px;
        padding: 2px 11px;
        color: #bbb;
      }

      .stars {
        margin-left: auto;
      }
    }
  }

  @media (max-width: 860px) {
    .text {
      span {
        display: block;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: normal;
        max-width: 75%;
      }
    }
  }

  .code {
    margin-left: 10px;
    font-size: ${fontSize.small};
    color: ${color.black60};
    flex-shrink: 0;
  }
  ${(props) =>
    props.isHotel === true &&
    css`
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: ${color.bgGrey};
      }
    `}
`

const AllAirports = styled.div`
  width: 100%;
  .title {
    text-align: left;
    padding-left: 34px;
    margin-bottom: 5px;
    font-size: 15px;
    color: ${color.black60};
    font-style: italic;
  }
  .suggestion {
    padding: 10px 10px 10px 34px;
    cursor: pointer;
    &:hover {
      background: ${color.bgGrey};
    }
  }
`
