import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import BannerImage from "../../images/reward_banner.png"

export default function Rewards() {
  let staticQuery = graphql`
    query RewardsImage {
      banner: file(relativePath: { regex: "/reward_banner.png/" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

  const { t } = useTranslation()
  return (
    <RewardsBox>
      <StaticQuery
        query={`${staticQuery}`}
        render={(data) => (
          <>
            <div className="container">
              <a href="https://www.travala.com/social-register">
                <Img
                  fluid={data.banner.childImageSharp.fluid}
                  style={{ height: "100%" }}
                  objectFit="contain"
                  imgStyle={{ objectPosition: "center" }}
                />
              </a>
            </div>
          </>
        )}
      />
    </RewardsBox>
  )
}

const RewardsBox = styled.div`
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;

  @media only screen and (max-width: 1275px) {
    width: 90%;
  }

  .container {
    padding: 48px 40px;

    h3 {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 20px;
    }

    h4 {
      font-size: 18px;
      font-weight: normal;
    }

    a {
      text-decoration: none;
    }

    .MuiButton-contained {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background: #fff;
      margin-top: 30px;
      border-radius: 8px;
      padding: 7px 20px;
      .MuiButton-label {
        font-size: 16px;
        color: #f66415;
        text-transform: none;
      }
    }

    @media only screen and (max-width: 1275px) {
      h4 {
        max-width: 50%;
      }
    }
    @media only screen and (max-width: 865px) {
      h4 {
        max-width: 70%;
      }
    }
    @media only screen and (max-width: 450px) {
      padding: 24px;
      h3 {
        font-size: 22px;
      }
      h4 {
        font-size: 16px;
      }
    }
`
