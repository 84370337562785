import { MenuItem, Select } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { FormControl } from "@material-ui/core"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const RenderChildren = (props) => {
  const [childAge, setChildAge] = useState(props.child.age)
  const [childAgeError, setChildAgeError] = useState({
    isInvalid: props.child.isInvalid,
  })

  const handleChange = (e) => {
    props.selectAge({
      id: props.child.id,
      index: props.index,
      age: e.target.value,
      isInvalid: false,
    })
    setChildAge(e.target.value)
  }

  const { t } = useTranslation()
  const ageAmounts = () => {
    let ageItems = []
    for (let i = 1; i < 18; i++) {
      ageItems.push(
        <MenuItem value={i}>
          {i} {t("years")}
        </MenuItem>
      )
    }
    return ageItems
  }

  return (
    <Wapper>
      <FormControl
        variant="outlined"
        formError={props.ageIsInvalid && childAgeError}
      >
        <Select
          value={childAge || -1}
          name="childAge"
          required
          disableUnderline
          onChange={handleChange}
        >
          <MenuItem value={-1}>{t("Select Age")} </MenuItem>
          {ageAmounts()}
        </Select>
      </FormControl>
    </Wapper>
  )
}

export default RenderChildren

const Wapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 10px;

  .MuiSelect-select {
    padding-right: 26px !important;
  }

  .MuiSelect-iconOutlined {
    right: 1px;
  }

  .MuiInputBase-root {
    min-width: 100px;
  }
`

const Label = styled.div`
  padding-left: 30px;
`
